import React from 'react'
import {Helmet} from 'react-helmet'

const SEO = ({data}) => {
    return (
        <div>
            <Helmet>
                <meta name="description" content={data.description}/>
                <meta name="og:title" property="og:title" content={data.ogTitle}/>
                <link rel="canonical" href={data.pageLink}/>
                <meta property="og:image" content={data.ogImage}/>


                <title>{data.title}</title>
                <link href="addons/shared_addons/themes/luxury/img/favicon.ico" rel="shortcut icon"
                      type="image/x-icon"/>
                <link rel="canonical" href={data.canonicalRef}/>
                <meta name="robots" content="index, follow"/>
                <link rel="alternate" type="application/rss+xml" title="LuxuryProperty.com"
                      href="https://www.luxuryproperty.com/blog/rss/all.rss"/>
                <meta name="keywords" content={data.keywords}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={data.ogUrl}/>
                <meta property="og:image:secure_url" content={data.secureImageUrl}/>
                <meta property="og:image:type" content="image/jpeg"/>
                <meta property="og:image:width" content="550"/>
                <meta property="og:image:height" content="375"/>
                <meta property="og:image:alt" content={data.ogImageAlt}/>
                <meta property="og:description" content={data.ogDescription}/>
                <meta property="og:site_name" content="Bnw Dubai"/>
                <meta property="og:locale" content="en_US"/>

                {/*<script async type="text/javascript" src="https://www.api.useleadbot.com/lead-bots/get-pixel-script.js"></script>
                <script type="text/javascript">{`window.rf_key_idf = "77e63adaf903445be9f611970b8a28db2cdfbfdc";`}</script>*/}

            </Helmet>
        </div>
    )
}

export default SEO
